.content-templatelocal_imagegallery {
  margin-top: 50px;
  margin-bottom: 50px;
  @include media-breakpoint-down(md) {
    margin-top: 25px;
    margin-bottom: 25px;
    .col:nth-child(3n+3) {
      max-width: 100%;
      flex-basis: 100%;
      .imagegallery-item {
        height: auto;
      }
    }
  }
  .imagegallery-item {
    height: 355px;
    @include media-breakpoint-down(sm) {
      height: 131px;
    }
    .overlay {
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: all .2s ease;
      visibility: hidden;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(6, 55, 91, 0.8);
      color: $white;
      font-size: 2.5rem;
      transition: all .3s ease-out;
    }
    &:hover {
      .overlay {
        opacity: 1;
        z-index: 1;
        visibility: visible;
      }
    }
  }
}

.row-cols-1 {
  .imagegallery-item {
    @include media-breakpoint-down(sm) {
      height: auto;
    }
  }
}

.fancybox__thumbs {
  display: none;
}
.compensate-for-scrollbar {
  padding-right: 0 !important;
}
.fancybox__toolbar,
.fancybox__nav {
  color: $white;
  .carousel__button:hover {
    color: $lightblue;
  }
}
.fancybox__nav {
  position: relative;
  height: 64px;
}
.fancybox__nav .carousel__button.is-prev {
  left: auto;
  right: 80px;
}
.fancybox__toolbar__items--right {
  margin-right: 30px;
}
.fancybox__viewport {
  height: calc(100% - 64px);
}


label,
.control-label {
  font-family: Montserrat;
  font-weight: $font-weight-bold;
  color: $text-color;
}
.custom-control-input,
.form-control {
  &:focus {
    outline: none;
    box-shadow: none;
  }
}
.custom-control-input:focus ~ .custom-control-label:before {
  box-shadow: none;
}

.custom-file-label:after {
  background: $primary;
  color: $white;
  font-family: Montserrat;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.5);
  font-weight: 600;
  cursor: pointer;
}
.custom-file-input {
  min-height: 37px;
  height: 100%;
}
.custom-select:focus,
.custom-file-input:focus ~ .custom-file-label {
  box-shadow: none;
  outline: none;
}
.custom-select {
  padding-right: 2.75rem;
}
.has-error {
  .form-label {
    color: $red;
  }
  .form-border-bottom,
  .hf-invalid,
  .error {
    border-color: $red;
  }
  .hs-input,
  .form-control {
    border-color: $red;
    &:focus {
      border-color: $lightblue;
    }
  }
  .hf-warning,
  .invalid-feedback {
    display: block;
    color: $red;
    font-size: .8125rem;
    padding: 0;
    margin-top: .25rem;
  }
}

.dropdown-toggle::after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "";
  border: none;
  transition: all .25s ease-in-out;
}

.bootstrap-select {
  width: 100% !important;
  transition: none !important;
  .dropdown-toggle {
    transition: none !important;
  }
  &.show {
    .dropdown-toggle {
      box-shadow: none !important;
      z-index: 1001;
      &:after {
        transform: rotate(-180deg);
      }
    }
    .inner {
      display: block;
    }
  }
  .dropdown-menu {
    padding: 0;
  }
}
.bootstrap-select .dropdown-toggle:focus, .bootstrap-select > select.mobile-device:focus + .dropdown-toggle {
  outline: none !important;
  transition: none !important;
}
.bootstrap-select > .dropdown-toggle.bs-placeholder, .bootstrap-select > .dropdown-toggle.bs-placeholder:active, .bootstrap-select > .dropdown-toggle.bs-placeholder:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder:hover {
  color: $gray-900;
}

.content-templatelocal_intro {
  position: relative;
  background: $blueradialbig;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.5);
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: $linebg;
    z-index: 0;
  }
  .text-area {
    text-shadow: $shadowtext;
  }
}

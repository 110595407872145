.content-templatelocal_fullwidthteaser {
  background: $blueradialbig;
  position: relative;
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: $linebg;
    z-index: 0;
  }
  .text-area {
    text-shadow: $shadowtext;
  }
}

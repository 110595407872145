.main-footer {
  padding: 0;
  background: $white;
  .nav-wrapper {
    padding: 1rem 15px;
    margin: 0 47px;
  }
  #footerbar {
    background: $darkblue;
  }
  .footer-logo {
    height: auto;
    @include media-breakpoint-down(md) {
      max-width: 160px;
    }
  }
  .f-menu {
    @include media-breakpoint-down(md) {
      width: 100%;
    }
    a {
      color: $white;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
}

//.fade { visibility: hidden; transition: visibility $animation-base, opacity $animation-base; }
//.fade.in { visibility: visible; }
$bluedakrblue: linear-gradient(68.19deg, #0E77BD 6.43%, #062E48 82.53%);
$bluedakrblue180: linear-gradient(180deg, #0E77BD 6.43%, #062E48 82.53%);
$blueradial: radial-gradient(67.66% 549.35% at 50% 40%, #0070BA 0%, #002A45 100%);
$blueradialbig: radial-gradient(118.43% 44.75% at 43.78% 19.48%, #0070BA 0%, #002A45 128%);
$shadowbase: 0px 4px 4px rgba(20, 76, 114, 0.25);
$shadowteaser: 2px 4px 8px rgba(0, 0, 0, 0.12);
$shadowteaserhover: 4px 4px 10px rgba(0, 0, 0, 0.25);
$shadowbottom: 2px 2px 3px rgba(0, 0, 0, 0.12);
$shadowtext: 0px 4px 4px rgba(0, 0, 0, 0.5), 5px 5px 10px rgba(0, 0, 0, 0.17);
$shadowinner: inset 5px 5px 50px rgba(0, 0, 0, 0.7);
$linebg: repeating-linear-gradient( 45deg, rgba(255,255,255,.06), rgba(255,255,255,.06) 1px, rgba(255,255,255,0) 2px, rgba(255,255,255,0) 7px );
$border-radius-box: 2px !default;
$text-65: rgba(0,0,0,.65);
$font-size-xs: 0.8125rem;

:root {
  --fancybox-bg: rgba(0,0,0,.8);
}

.frame-space-before-extra-small { padding-top: 10px !important; }
.frame-space-before-small { padding-top: 25px !important; }
.frame-space-before-medium { padding-top: 50px !important; }
.frame-space-before-large { padding-top: 75px !important; }
.frame-space-before-extra-large { padding-top: 100px !important; }
.frame-space-after-extra-small { padding-bottom: 10px !important; }
.frame-space-after-small { padding-bottom: 25px !important; }
.frame-space-after-medium { padding-bottom: 50px !important; }
.frame-space-after-large { padding-bottom: 75px !important; }
.frame-space-after-extra-large { padding-bottom: 100px !important; }

@include media-breakpoint-down(sm) {
  .frame-space-before-extra-small { padding-top: 5px !important; }
  .frame-space-before-small { padding-top: 20px !important; }
  .frame-space-before-medium { padding-top: 40px !important; }
  .frame-space-before-large { padding-top: 60px !important; }
  .frame-space-before-extra-large { padding-top: 90px !important; }
  .frame-space-after-extra-small { padding-bottom: 5px !important; }
  .frame-space-after-small { padding-bottom: 20px !important; }
  .frame-space-after-medium { padding-bottom: 40px !important; }
  .frame-space-after-large { padding-bottom: 60px !important; }
  .frame-space-after-extra-large { padding-bottom: 90px !important; }
}

@include media-breakpoint-down(md) {
  .frame-space-before-extra-small { padding-top: 5px !important; }
  .frame-space-before-small { padding-top: 20px !important; }
  .frame-space-before-medium { padding-top: 40px !important; }
  .frame-space-before-large { padding-top: 60px !important; }
  .frame-space-before-extra-large { padding-top: 90px !important; }
  .frame-space-after-extra-small { padding-bottom: 5px !important; }
  .frame-space-after-small { padding-bottom: 20px !important; }
  .frame-space-after-medium { padding-bottom: 40px !important; }
  .frame-space-after-large { padding-bottom: 60px !important; }
  .frame-space-after-extra-large { padding-bottom: 90px !important; }
}

html {
  overflow: hidden;
  overflow-y: scroll;
  width: 100%;
}
body {
  overflow-x: hidden;
  max-width: 100vw;
  padding-top: 150px;
  transition: all .35s ease;
  @include media-breakpoint-down(md) {
    padding-top: 93px;
  }
}
body.overflow-hidden {
  height: 100vh !important;
  height: calc(var(--vh, 1vh) * 100) !important;
  margin-top: calc(var(--meta));
  .main-header {
    margin-top: calc(var(--meta));
  }
}
// content wrapper
.content-wrapper {
  padding: 0 $grid-gutter-width/2;
  max-width: $max-width-content-wide;
  margin: $content-wrapper-padding/2 auto;
}
.element-wrapper { margin-bottom: $spacer*2}

.navigation-wrap {
  max-width: $max-width-content-wide;
  margin: 0 auto;
  padding: 0 $grid-gutter-width/2;
  width: 100%;
}


h1,.h1 {
  letter-spacing: 0.04em;
}
h2,.h2 {
  color: $primary;
}
.text-darkblue,
h3,.h3,
h4,.h4 {
  color: $darkblue;
}
h6,.h6 {
  font-family: Montserrat;
  letter-spacing: 3px;
  color: $gray-900;
}
.lead {
  line-height: 1.5375rem;
}

@include media-breakpoint-down(md) {
  h1,.h1 {
    font-size: 2rem;
    line-height: 2rem;
  }
  h2,.h2 {
    font-size: 1.75rem;
    line-height: 1.925rem;
  }
  h3,.h3 {
    font-size: 1.5625rem;
    line-height: 1.64rem;
  }
  h4,.h4 {
    font-size: 1.375rem;
    line-height: 1.5125rem;
  }
  h5,.h5 {
    font-size: 1.1875rem;
    line-height: 1.3rem;
  }
  h6,.h6 {
    font-size: .75rem;
    line-height: .825rem;
  }
}

.btn {
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.5);
  outline: none;
  @include media-breakpoint-down(md) {
    padding: $btn-padding-y-sm $btn-padding-x-sm;
    font-size: $btn-font-size-sm;
    line-height: $btn-line-height-sm;
  }
  &.btn-primary {
    &:not(:disabled).active,
    &:focus,&:hover {
      background-image: $blueradial;
    }
  }
  &.btn-secondary {
    background: transparent;
    border-color: $primary;
    color: $primary;
    box-shadow: none;
    &:not(:disabled).active,
    &:hover,&:focus {
      color: $white;
      background-color: $lightblue;
      border-color: $lightblue;
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), 2px 2px 10px 4px rgba(0, 0, 0, 0.2);
    }
    &.white {
      color: $white;
      border-color: $white;
      box-shadow: none;
      &:not(:disabled).active,
      &:hover,&:focus {
        color: $darkblue;
        background-color: $white;
        border-color: $white;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), 2px 2px 10px 4px rgba(0, 0, 0, 0.2);
      }
    }
  }
  &.btn-link {
    color: $gray-800;
    text-decoration: none;
    font-weight: 600;
    &:not(:disabled).active,
    &:hover,&:focus {
      color: $gray-900;
      text-decoration: none;
    }
  }
  &.btn-responsive {
    @include media-breakpoint-down(md) {
      padding: $btn-padding-y-sm $btn-padding-x-sm;
      font-size: $btn-font-size-sm;
      line-height: $btn-line-height-sm;
    }
  }
}

.white-shadow-box {
  background: $white;
  box-shadow: $shadowteaser;
  border-radius: $border-radius-box;
}
.hover-shadow {
  transition: all .3s ease-out;
  &:hover {
    box-shadow: $shadowteaserhover;
  }
}

.px-grid {
  padding-left: $grid-gutter-width/2;
  padding-right: $grid-gutter-width/2;
  @include media-breakpoint-down(sm) {
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
}
.px-25 {
  padding-left: 25px;
  padding-right: 25px;
}

.px25 {
  padding-left: 2.5px;
  padding-right: 2.5px;
}
.py-150 {
  padding-top: 150px;
  padding-bottom: 150px;
  @include media-breakpoint-down(md) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
.p-75 {
  padding: 75px;
  @include media-breakpoint-down(md) {
    padding: 40px 20px;
  }
}
.pt-75 {
  padding-top: 75px;
  @include media-breakpoint-down(md) {
    padding-top: 48px;
  }
}

.py-50 {
  padding-top: 50px;
  padding-bottom: 50px;
  @include media-breakpoint-down(md) {
    padding-bottom: 25px;
  }
}
.mb-25 {
  margin-bottom: 25px;
}
.mb-30 {
  margin-bottom: 30px;
}
.py-32 {
  padding-top: 32px;
  padding-bottom: 32px;
  @include media-breakpoint-down(md) {
    padding-top: 7.5px;
    padding-bottom: 7.5px;
  }
}
.p-100 {
  padding: 100px 30px;
  @include media-breakpoint-down(md) {
    padding: 80px 30px;
  }
}
.grid9 {
  margin-left: -9px;
  margin-right: -9px;
  .col {
    padding-left: 4.5px;
    padding-right: 4.5px;
    margin-bottom: 9px;
  }
}
.link-icon {
  color: $primary;
  font-size: 1.125rem;
}

.text-65 {
  color: $text-65;
}
.my-75 {
  margin-bottom: 75px;
  margin-top: 75px;
  @include media-breakpoint-down(md) {
    margin-bottom: 25px;
    margin-top: 25px;
  }
}
.w-lg-auto {
  @include media-breakpoint-up(lg) {
    width: auto !important;
  }
}
.vh-100 {
  height: 100vh !important;
  height: calc(var(--vh, 1vh) * 100) !important;
}
.ti-text-wrap {
  align-self: center;
  margin-left: -16.66667%;
  z-index: 1;
  .white-shadow-box {
    padding: 130px 100px;
  }
  @include media-breakpoint-down(md) {
    margin-left: 0;
    margin-right: 0;
    margin-top: -16.66667%;
    .white-shadow-box {
      padding: 40px 20px;
    }
  }
}
.img-right {
  .ti-text-wrap {
    margin-right: -16.66667%;
    margin-left: 0;
  }
}
.ti-img-wrap {
  @include media-breakpoint-down(md) {
    padding: 0;
  }
}
.ce-textpic {
  .ti-image img {
    transition: transform .3s ease-out;
  }
  &:hover {
    .ti-image img {
      transform: scale(1.2);
    }
  }
}

.modal-footer {
  padding: .625rem 1.5625rem;
}
.modal-body {
  padding: 1.5625rem;
}

.modal-open {
  height: 100vh !important;
  height: calc(var(--vh, 1vh) * 100) !important;
  padding-right: 0 !important;
  transition: none;
  .main-header {
    padding-right: 0 !important;
    transition: none;
  }
  .modal {
    overflow: hidden;
  }
  .modal-content {
    height: 100%;
  }
  .success-modal {
      height: auto;
  }
}
.text-small {
  font-size: $font-size-xs;
}
.success-modal {
  background: $white;
  border: 1px solid $green1;
  .close {
    position: absolute;
    right: 1rem;
    top: 1rem;
    z-index: 1;
  }
  .modal-body {
    padding: 100px;
    height: auto;
    overflow: hidden;
    @include media-breakpoint-down(md) {
      padding: 25px;
    }
  }
}
.fa-25 {
  font-size: 2.5em;
}
.text-green {
  color: $green1;
}

.modal {
  height: calc(var(--vh, 1vh) * 100) !important;
}
.modal-content {
  height: 100%;
}
.modal-body {
  padding: 1rem;
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
  .row {
    margin-left: 0;
    margin-right: 0;
  }
}

.modal-dialog {
  height: calc(100% - 3.5rem);
  @include media-breakpoint-down(md) {
    height: calc(var(--vh, 1vh) * 100) !important;
    margin: 0;
    position: fixed;
    width: 100%;
    .modal-header {
      padding: 1rem 1.5rem;
    }
    .success-modal,
    .modal-content {
      border: none;
      height: 100%;
    }
    .modal-footer {
      padding: .8125rem 1rem;
    }
  }
}

.image-layout-0 {
  /*max-width: $max-width-content-wide;
  margin: 0 auto;
  padding: 0 $grid-gutter-width/2;*/
  width: 100%;
  .col {
    height: 500px;
    @include media-breakpoint-down(md) {
      height: 200px;
    }
    min-width: 100%;
    flex-basis: 100%;
    margin-bottom: 40px;
    .focuspoint {
      &:after {
        display: block;
        top: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
        content: "";
        box-shadow: $shadowinner;
      }
    }
    img {
      transition: transform .3s ease-out;
    }
    &:hover {
      img {
        transform: scale(1.2);
      }
    }
  }
}
.image-layout-1 {
  .col {
    min-width: 100%;
    flex-basis: 100%;
    margin-bottom: 40px;
    .ratio-container {
      &:before {
        display: block;
        top: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
        content: "";
        box-shadow: $shadowinner;
      }
    }
  }
}
.image-layout-2 {
  max-width: $max-width-content-wide;
  margin: 0 auto;
  padding: 0 $grid-gutter-width/2;
  width: 100%;
  .col {
    margin-bottom: 25px;
    .ratio-container {
      box-shadow: $shadowteaserhover;
      border-radius: 2px;
    }
  }
}

.ce-textpic {
  @include media-breakpoint-down(md) {
    padding-left: $grid-gutter-width/2;
    padding-right: $grid-gutter-width/2;
  }
  img {
    transition: transform .3s ease-out;
  }
  &:hover {
    img {
      transform: scale(1.2);
    }
  }
}

ul,ol {
  padding-left: 1.25rem;
}
ol {
  > li::marker {
    font-weight: bold;
  }
}
[data-galleryfancybox],
[data-imagefancybox] {
  &:hover {
    cursor: pointer;
  }
}

.display-4 {
  letter-spacing: 2px;
}

.ti-img-wrap .focuspoint {
  border-radius: 2px;
}

.ce-bodytext {
  .btn {
    margin-top: .625rem;
  }
}

a {
  .fa:only-child {
    margin: 0;
  }
  .fa:first-child {
    margin-right: .5rem;
  }
  .fa:last-child {
    margin-left: .5rem;
  }
}

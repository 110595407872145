.content-templatelocal_keyvisual {
  .bg-area {
    width: 43%;
    position: relative;
    z-index: 1;
    .bg-area-wrap {
      background: $blueradialbig;
      box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.5);
      transform: skewX(-12deg);
      position: absolute;
      width: 124%;
      height: 100%;
      left: -12%;
      overflow: hidden;
    }
    .overlay {
      position: absolute;
      width: 200%;
      height: 100%;
      background: $linebg;
      transform: skewX(12deg);
    }
    @include media-breakpoint-down(md) {
      width: 100%;
      padding-bottom: 20vh;
    }
}
  .text-area {
    width: 100%;
    height: 100%;
    z-index: 1;
    transform: translateY(-100%);
    overflow: hidden;
    text-shadow: $shadowtext;
  }
  .image-area {
    max-width: 57%;
    max-height: 550px;
    @include media-breakpoint-down(md) {
      max-width: 100%;
      max-height: 40vh;
      transform: translateY(-50%);
      padding: 0 12.5px;
      margin-bottom: -20vh;
      position: relative;
      z-index: 1;
      .focuspoint {
        border-radius: $border-radius-box;
        box-shadow: $shadowteaserhover;
      }
    }
  }
  .key-text-mobile {
    padding: 15px 15px 50px 15px;
  }
  .key-header-mobile {
    padding: 50px 15px;
  }
}

.main-header {
  background: $gray-100;
  transition: all .35s ease;
  .metanav {
    .phone {
      padding: 1.281rem .78125rem;
    }
    .nav-link:not(.phone) {
      color: $black;
      font-size: .8125rem;
      padding: 1.4215rem .78125rem;
      height: 100%;
      @include media-breakpoint-down(md) {
        padding: .7345rem .78125rem;
      }
      &.active,
      &:hover {
        background: $white;
        color: $darkblue;
      }
    }
  }
  .top-bar-section {
    > .nav {
      > .nav-item > .nav-link {
        &:last-child {
          padding-right: 0;
        }
      }
    }
    .nav-item {
      position: relative;
      a:hover {
        color: $lightblue;
      }

      .subtrigger {
        position: absolute;
        right: -1rem;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        transition: all .35s ease;
        z-index: 1;
        &:hover {
          color: $lightblue;
        }
        @include media-breakpoint-down(md) {
          width: 41px;
          height: 41px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.125rem;
          right: 1rem;
          top: 19px;
          &:after {
            display: block;
            content: "";
            width: 1px;
            height: 20px;
            background: $gray-800;
            left: 0;
            position: absolute;
          }
          &:before{
            transition: all .35s ease;
          }
        }
      }

      .dropdown-menu {
        padding: 0;
        margin: 0;
        &:before {
          display: block;
          position: absolute;
          left: 0;
          right: 0;
          top: -3px;
          height: 2px;
          content: "";
          z-index: 1031;
          box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.12);
        }
        .dropdown-item {
          padding: 0;
          font-size: 1rem;
          position: relative;
          a {
            padding: .625rem;
            @include media-breakpoint-down(md) {
              padding: .44rem 4.625rem .44rem .625rem;
            }
            display: block;
            color: $text-65;
            text-decoration: none;
            &:hover,
            &.active {
              color: $lightblue;
            }
          }
          &.dropdown-submenu {
            a {
              padding-right: 4.625rem;
              &.show {
                color: $lightblue;
                + .subtrigger {
                  color: $lightblue;
                  @include media-breakpoint-down(md) {
                    &:before {
                      transform: rotate(-90deg);
                    }
                  }
                }
              }
            }
            .subtrigger {
              width: 41px;
              height: 41px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: .875rem;
              right: 0;
              &:after {
                display: block;
                content: "";
                width: 1px;
                height: 20px;
                background: $gray-800;
                left: 0;
                position: absolute;
              }
              @include media-breakpoint-down(md) {
                &:before {
                  transform: rotate(90deg);
                }
              }
            }
            &:hover {
              color: $lightblue;
            }
            > .dropdown-menu {
              top: 0;
              left: 100%;
            }
          }
        }
        @include media-breakpoint-down(md) {
          position: relative !important;
          transform: translate3d(0,0,0) !important;
          box-shadow: none;
          left: 0 !important;
          padding: 0 1rem;
          &:before {
            display: none;
          }
          &.show {
            .subtrigger {
              &:before {
                transform: rotate(-180deg);
              }
            }
          }
        }
        @include media-breakpoint-down(md) {
          max-height: 0;
          overflow: hidden;
          display: block;
          transition: max-height .35s ease;
          &.show {
            max-height: 100vh;
            transition: max-height .35s ease;
          }
        }
      }
    }
    .show {
      > .subtrigger {
        color: $lightblue;
        @include media-breakpoint-down(md) {
          &:before {
            transform: rotate(-180deg);
          }
        }
      }
    }
    .nav-link {
      color: $gray-900;
      font-size: 1.125rem;
      font-weight: 600;
      padding: 1.8125rem 1.5rem;
      @include media-breakpoint-down(md) {
        padding: .44rem 4.625rem .44rem 1rem;
      }
      &.active,
      &:hover {
        color: $lightblue;
      }
    }
  }
}
.d-shadow {
  box-shadow: $shadowbottom;
}
.logo {
  @include media-breakpoint-down(md) {
    max-width: 35%;
    min-height: 40px;
    line-height: 50px;
  }
}
.hideme {
  margin-top: calc(var(--meta));
}
.hamburger {
  @include media-breakpoint-down(md) {
    margin-right: -.75rem;
  }
}
.menu-wrap {
  @include media-breakpoint-down(md) {
    padding: 0;
    max-height: calc(var(--vh, 1vh) * 100 - 50px);
    overflow-y: auto;
  }
}
.logo-wrap {
  @include media-breakpoint-down(md) {
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.12);
  }
}

.PartnerWall-item {
  text-align: center;
  margin: 17.5px 0;
  flex-basis: 130px;
  max-width: 20%;
  img {
    transition: all .25s ease;
    opacity: .5;
    @include media-breakpoint-down(md) {
      opacity: 1;
    }
  }
  &:hover {
    img {
      opacity: 1;
      transform: scale(1.1);
    }
  }
  @include media-breakpoint-down(md) {
    flex-basis: 160px;
    max-width: 40%;
  }
}

.references-slider {
  @include media-breakpoint-down(sm) {
    margin-left: -12.5px;
    margin-right: -12.5px;
    &.swiper-initialized {
      padding-right: 20vw;
    }
    .row {
      margin: 0;
    }
  }
  [class ~= swiper-slide-duplicate] {
    @include media-breakpoint-up(md) {
      display: none;
    }
  }
}

.flyout {
  position: static !important;
  .flyout-menu {
    position: absolute;
    background: $flyout-background;
    left: 0;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    transition: visibility $animation-base, opacity $animation-base;
    z-index: 1;
    @if $enable-flex {
      display: flex;
      flex-wrap: nowrap;
    }
  }
}

.flyout:hover {
  .flyout-menu {
    visibility: visible;
    opacity: 1;
  }
}

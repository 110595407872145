.salmcalendar-container {
  color: $black;
}
.salmcalendar-link {
  color: $black;
  display: inline-block;
}
.salmcalendar-link:hover{
  color: $lightblue !important;
}

.bg-md-white {
  @include media-breakpoint-up(lg) {
    background: $white;
  }
}

.salmcalendar-legend-title {
  font-weight: bold;
  margin-top: 24px;
  margin-bottom: 40px;
}
.salmcalendar-legend-type {
  position: relative;
}
.salmcalendar-legend-type-1,
.salmcalendar-legend-type-2,
.salmcalendar-legend-type-3,
.salmcalendar-legend-type-4 {
  position: relative;
  width: 47px;
  height: 20px;
}
.salmcalendar-legend-type-1 {
  background: $white;
  border: 2px solid $green1;
  box-sizing: border-box;
  border-radius: 2px;
}
.salmcalendar-legend-type-2 {
  background: $green1;
  border-radius: 2px;
}
.salmcalendar-legend-type-3 {
  background: $gray-800;
  border-radius: 2px;
}
.salmcalendar-legend-type-4 {
  background: $gray-400;
  border-radius: 2px;
}
.salmcalendar-table .fa {
  font-weight: normal;
  font-size: 32px;
  line-height: 100%;
}
.salmcalendar-table .fa.fa-arrow-right {
  text-align: right;
  position: absolute;
  right: 0;
}
.salmcalendar-date-text {
  font-weight: bold;
  font-size: 31px;
  line-height: 110%;
  color: #0E77BD;
}
.salmcalendar-day {
  font-weight: normal;
  color: $gray-800;
  text-align: center;
}
.salmcalendar-actual-day {
  font-weight: bold;
  color: $black;
  text-align: center;
}
.salmcalendar-table {
  table-layout: fixed;
  width: 100%;
}
.salmcalendar-table thead td {
  text-align: center;
  padding-left: 40px;
  white-space: nowrap;
  @include media-breakpoint-down(md) {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
.overflowContainer {
  .salmcalendar-table thead td {
    padding-left: 10px;
  }
}
.salmcalendar-table thead tr:first-child td {
  text-align: left;
}
.salmcalendar-table thead tr:first-child td:first-child {
  padding-left: 0;
}
.overflowContainer {
  .salmcalendar-table thead tr:first-child td:first-child {
    padding-left: 10px;
  }
}
.salmcalendar-table tbody td {
  border-top: 1px dashed $gray-400;
}
.salmcalendar-table td:first-child {
  width: 76px;
}

.overflowContainer {
  .salmcalendar-table td:first-child {
    width: 55px;
  }
}

.salmcalendar-table tbody td:first-child {
  border-top: none;
}
.overflowContainer {
  .salmcalendar-table tbody td:first-child {
    border-top: 1px dashed $gray-400;
  }
}
.salmcalendar-table td {
  height: 66px;
  width: 149px;
  max-width: 149px;
  position: relative;
  @include media-breakpoint-down(md) {
    height: 36px;
    width: 55px;
    max-width: 55px;
  }
}
.appointment-hour {
  position: absolute;
  right: 8px;
  top: -12px;
}
.appointment-type {
  width: calc(100% - 9px);
  position: absolute;
  left: 28px;
  top: 4px;
  z-index: 999;
}
.overflowContainer {
  .appointment-type {
    left: 10px;
  }
}
.appointment-type-1 {
  background: $white;
  border: 2px solid $green1;
  box-sizing: border-box;
  border-radius: 2px;
  cursor: pointer;
  transition: all .3s ease-out;
  box-shadow: $shadowbottom;
  &:hover {
    background: #AFF1D9;
  }
}
.appointment-type-2 {
  background: $green1;
  border-radius: 2px;
}
.appointment-type-3 {
  background: $gray-800;
  border-radius: 2px;
}
.appointment-type-4 {
  background: $gray-400;
  border-radius: 2px;
}
.appointment-duration-,
.appointment-duration-0 {
  height: 0;
}
.appointment-duration-1 {
  height: calc(100% - 8px);
}
.appointment-duration-2 {
  height: calc(200% - 7px);
}
.appointment-duration-3 {
  height: calc(300% - 6px)
}
.appointment-duration-4 {
  height: calc(400% - 5px)
}
.appointment-duration-5 {
  height: calc(500% - 4px)
}
.appointment-duration-6 {
  height: calc(600% - 3px)
}
.appointment-duration-7 {
  height: calc(700% - 2px)
}
.appointment-duration-8 {
  height: calc(800% - 1px)
}
.appointment-duration-9 {
  height: calc(900%)
}
.appointment-duration-10 {
  height: calc(1000% + 1px);
}
.appointment-duration-11 {
  height: calc(1100% + 2px);
}
.appointment-duration-12 {
  height: calc(1200% + 3px);
}
.appointment-duration-13 {
  height: calc(1300% + 4px);
}
.appointment-duration-14 {
  height: calc(1400% + 5px);
}

.responsiveTableContainer {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 5px;
  @include media-breakpoint-down(md) {
    margin: 0 -12.5px;
  }
  background: $white;
}
.overflowContainer,
.staticContainer {
  float: none !important;
}

.staticContainer {
  box-shadow: $shadowbottom;
  position: relative;
  z-index: 2;
  @include media-breakpoint-down(md) {
    padding: 0 12.5px;
  }
  .salmcalendar-table {
    border: none !important;
  }
}

.overflowContainer {
  margin-bottom: -16px;
  &:after {
    display: block;
    height: 5px;
    position: absolute;
    width: 100vw;
    left: 0;
    z-index: 0;
    content: "";
    background: $gray-100;
  }
}
.table-wrapper {
  margin: 0 -12.5px;
  padding: 0 12.5px;
}

.date-nav {
  .date-next {
    margin-left: 2.25rem;
  }
  a + .date-next {
    margin-left: .5rem;
  }
}
.date-info {
  background: $darkblue;
  color: $white;
}

.team-item {
  padding: 25px;
  @include media-breakpoint-down(md) {
    padding: 40px 25px;
  }
  .img-wrap {
    padding: 5px;
    background-image: $bluedakrblue180;
    border-radius: 50%;
  }
}
